@media screen and (max-width: 500px) {
  div.housing table {
    width: 90%;
    font-size: 14px;
  }
}

.housing table {
  width: 500px;
  border-collapse: collapse;
  margin-top: 1rem;
  color: rgba(0, 0, 0, .6);
}

.housing table th {
  font-size: 14px;
}

.housing table td {
  padding-left: 8px;
  width: 20%;
  font-weight: bold;
}

.housing table td:nth-child(2) {
  width: 80%;
}

.housing table, th, td {
  border: 1px solid black;
}

.housing td:first-child {
  text-align: center;
}

.highlight {
  color: #000;
}