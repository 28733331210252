@media screen and (max-width: 500px) {
  .section .body {
    font-size: 13px;
    font-size: 3.3vw;
    letter-spacing: .01em;
  }

  div.section .body .col {
    margin-left: .5rem;
  }

  .verse.img span {
    font-size: 5.5vw !important;
  }
}

@media screen and (min-width: 500px) and (max-width: 850px) {
  .section .body {
    font-size: 12px;
    font-size: 3.3vw;
    letter-spacing: .02em;
  }
}

.invitation {
  font-family: 'Shadows Into Light Two', cursive;
  font-size: 20px;
}

.section {
  background-color: rgb(245, 233, 207);
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  overflow: hidden;
}

.header {
  text-align: center;
}

.header p {
  margin: 0 0 10px 0;
}

.section .body {
  margin-top: 1rem;
  display: flex;
}

.section .body .col {
  text-align: left;
  margin-left: 1rem;
}

.section .body .verse {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}

.what {
  font-size: 28px;
}

.who {
  font-size: 36px;
}

.verse.img {
  display: inline-block !important;
  text-align: center;
}

.verse img {
  width: 50px;
}

.verse.img span {
  font-size: 25px;
}

.amp {
  background-image: url(/font.svg);
  width: .7em;
  height: 1em;
  background-size: contain;
  display: inline-block;
  background-repeat: no-repeat;
}

.mw560 {
  max-width: 560px;
  max-width: calc(min(560px, 100vw));
}

.media {
  margin-bottom: 3rem;
}

iframe {
  max-width: 100vw;
}

.ml {
  margin-left: 2rem;
}