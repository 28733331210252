.schedule {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(245, 233, 207);
}

.schedule p {
  margin: 0;
}

.schedule .mb {
  margin-top: 8px;
}

.schedule .center {
  text-align: center;
}

.schedule > div {
  max-width: 560px;
}
