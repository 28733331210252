@import url('https://fonts.googleapis.com/css?family=Shadows+Into+Light+Two&display=swap');
@import url('https://fonts.googleapis.com/css?family=Courgette&display=swap');

body::after {
  content: "";
  background-image: url('/bg.jpg');
  opacity: 0.4;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: -1;
}

a {
  color: inherit;
}

body {
  font-family: 'Shadows Into Light Two', cursive;
  letter-spacing: 3px;
  line-height: 30px;
  font-size: 16px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app {
  max-width: 900px;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

.timage {
  background-image: url('/handsc.jpg');
  background-repeat: no-repeat;
  width: 100%
}

.hands {
  width: 100%;
}

.section {
  animation: fade-in .6s ease;
}

@keyframes fade-in {
  0% {
      opacity: 0;

  }
  100% {
      opacity: 1;

  }
}

.alc {
  text-align: center;
}

.alc img {
  max-width: 100vw;
}