@import url(https://fonts.googleapis.com/css?family=Shadows+Into+Light+Two&display=swap);
@import url(https://fonts.googleapis.com/css?family=Courgette&display=swap);
body::after {
  content: "";
  background-image: url('/bg.jpg');
  opacity: 0.4;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: -1;
}

a {
  color: inherit;
}

body {
  font-family: 'Shadows Into Light Two', cursive;
  letter-spacing: 3px;
  line-height: 30px;
  font-size: 16px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app {
  max-width: 900px;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

.timage {
  background-image: url('/handsc.jpg');
  background-repeat: no-repeat;
  width: 100%
}

.hands {
  width: 100%;
}

.section {
  -webkit-animation: fade-in .6s ease;
          animation: fade-in .6s ease;
}

@-webkit-keyframes fade-in {
  0% {
      opacity: 0;

  }
  100% {
      opacity: 1;

  }
}

@keyframes fade-in {
  0% {
      opacity: 0;

  }
  100% {
      opacity: 1;

  }
}

.alc {
  text-align: center;
}

.alc img {
  max-width: 100vw;
}

div.food {
  padding-bottom: 0;
}

.foodi {
  display: flex;
  width: 100%;
}

.food img {
  width: 16rem;
  height: auto;
  align-self: flex-end;
  padding-right: 10%;
}

@media screen and (max-width: 520px) {
  .foodi {
    flex-direction: column-reverse;
    align-items: center;
  }

  div.foodi img {
    align-self: flex-start;
  }
}

@media screen and (max-width: 500px) {
  div.housing table {
    width: 90%;
    font-size: 14px;
  }
}

.housing table {
  width: 500px;
  border-collapse: collapse;
  margin-top: 1rem;
  color: rgba(0, 0, 0, .6);
}

.housing table th {
  font-size: 14px;
}

.housing table td {
  padding-left: 8px;
  width: 20%;
  font-weight: bold;
}

.housing table td:nth-child(2) {
  width: 80%;
}

.housing table, th, td {
  border: 1px solid black;
}

.housing td:first-child {
  text-align: center;
}

.highlight {
  color: #000;
}

.info p {
  margin: 0;
}

@media screen and (max-width: 500px) {
  .section .body {
    font-size: 13px;
    font-size: 3.3vw;
    letter-spacing: .01em;
  }

  div.section .body .col {
    margin-left: .5rem;
  }

  .verse.img span {
    font-size: 5.5vw !important;
  }
}

@media screen and (min-width: 500px) and (max-width: 850px) {
  .section .body {
    font-size: 12px;
    font-size: 3.3vw;
    letter-spacing: .02em;
  }
}

.invitation {
  font-family: 'Shadows Into Light Two', cursive;
  font-size: 20px;
}

.section {
  background-color: rgb(245, 233, 207);
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  overflow: hidden;
}

.header {
  text-align: center;
}

.header p {
  margin: 0 0 10px 0;
}

.section .body {
  margin-top: 1rem;
  display: flex;
}

.section .body .col {
  text-align: left;
  margin-left: 1rem;
}

.section .body .verse {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}

.what {
  font-size: 28px;
}

.who {
  font-size: 36px;
}

.verse.img {
  display: inline-block !important;
  text-align: center;
}

.verse img {
  width: 50px;
}

.verse.img span {
  font-size: 25px;
}

.amp {
  background-image: url(/font.svg);
  width: .7em;
  height: 1em;
  background-size: contain;
  display: inline-block;
  background-repeat: no-repeat;
}

.mw560 {
  max-width: 560px;
  max-width: calc(min(560px, 100vw));
}

.media {
  margin-bottom: 3rem;
}

iframe {
  max-width: 100vw;
}

.ml {
  margin-left: 2rem;
}
.map {
  text-align: center;
}

.map img {
  margin-top: 1rem;
  width: 100%;
}
.menu {
  background-color: rgb(245, 233, 207);
  font-size: 18px;
  display: flex;
  padding: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.menu > a {
  margin-left: 1.5rem;
}

.is-active {
  font-weight: bold;
}
.schedule {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(245, 233, 207);
}

.schedule p {
  margin: 0;
}

.schedule .mb {
  margin-top: 8px;
}

.schedule .center {
  text-align: center;
}

.schedule > div {
  max-width: 560px;
}

.separator {
  position: relative;
  height: 40px;
  padding-top: 40px;
}

.separator img {
  width: 40px;
  position: absolute;
  top: 16px;
  left: calc(50% - 20px);
  z-index: 1;
}

.separator hr {
  margin: 0;
  position: absolute;
}

.separator hr.left {
  width: calc(50% - 20px);
}

.separator hr.right {
  left: calc(50% + 15px);
  width: calc(50% - 15px);
}
@media screen and (max-width: 500px) {
  div.table.section div.left {
    width: 50%;
  }

  div.table.section {
    flex-direction: column;
    flex-direction: column-reverse;
    align-items: center;;
  }
  div.table.section table {
    width: 100%;
    font-size: 13px;
    line-height: 16px;
  }

  div.table.section table td:first-child {
    width: 50%;
  }

  div.parent.right > div {
    height: 40px;
    width: 50px;
  }
  div.parent.right > div.reverse {
    width: 40px;
    height: 50px;
  }
}

.parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.parent > div {
  width: 90px;
  height: 50px;
  background-color: #efdbb0;
  text-align: center;
}

.parent div.reverse {
  width: 50px;
  height: 90px;
  justify-self: flex-end;
}

.parent div.top {
  align-self: flex-end;
}

.parent > div > span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.div1 { grid-area: 1 / 1 / 2 / 2; }
.div2 { grid-area: 1 / 2 / 2 / 3; }
.div3 { grid-area: 2 / 2 / 3 / 3; }
.div4 { grid-area: 3 / 2 / 4 / 3; }
.div5 { grid-area: 4 / 2 / 5 / 3; }
.div6 { grid-area: 5 / 2 / 6 / 3; }
.div7 { grid-area: 6 / 2 / 7 / 3; }
.div8 { grid-area: 6 / 1 / 7 / 2; }

.table.section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  color: rgba(0, 0, 0, .7);
}

.table.section .left {
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 54%
}

.table.section .right {
  padding-right: 20px;
}

td.table-header {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  -webkit-transform: translateX(-30px);
          transform: translateX(-30px);
  padding-bottom: 1rem !important;
}

.table.section table td {
  border: none;
  padding: 0;
}

.table.section table {
  margin-top: 2rem;
  width: 230px;
  border-collapse: collapse;
  line-height: 20px;
}

.table.section table td:first-child {
  width: 115px;
}

.table.section table .highlight {
  color: #000;
  font-weight: bold;
}

