div.food {
  padding-bottom: 0;
}

.foodi {
  display: flex;
  width: 100%;
}

.food img {
  width: 16rem;
  height: auto;
  align-self: flex-end;
  padding-right: 10%;
}

@media screen and (max-width: 520px) {
  .foodi {
    flex-direction: column-reverse;
    align-items: center;
  }

  div.foodi img {
    align-self: flex-start;
  }
}
