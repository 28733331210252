@media screen and (max-width: 500px) {
  div.table.section div.left {
    width: 50%;
  }

  div.table.section {
    flex-direction: column;
    flex-direction: column-reverse;
    align-items: center;;
  }
  div.table.section table {
    width: 100%;
    font-size: 13px;
    line-height: 16px;
  }

  div.table.section table td:first-child {
    width: 50%;
  }

  div.parent.right > div {
    height: 40px;
    width: 50px;
  }
  div.parent.right > div.reverse {
    width: 40px;
    height: 50px;
  }
}

.parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.parent > div {
  width: 90px;
  height: 50px;
  background-color: #efdbb0;
  text-align: center;
}

.parent div.reverse {
  width: 50px;
  height: 90px;
  justify-self: flex-end;
}

.parent div.top {
  align-self: flex-end;
}

.parent > div > span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.div1 { grid-area: 1 / 1 / 2 / 2; }
.div2 { grid-area: 1 / 2 / 2 / 3; }
.div3 { grid-area: 2 / 2 / 3 / 3; }
.div4 { grid-area: 3 / 2 / 4 / 3; }
.div5 { grid-area: 4 / 2 / 5 / 3; }
.div6 { grid-area: 5 / 2 / 6 / 3; }
.div7 { grid-area: 6 / 2 / 7 / 3; }
.div8 { grid-area: 6 / 1 / 7 / 2; }

.table.section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  color: rgba(0, 0, 0, .7);
}

.table.section .left {
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 54%
}

.table.section .right {
  padding-right: 20px;
}

td.table-header {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  transform: translateX(-30px);
  padding-bottom: 1rem !important;
}

.table.section table td {
  border: none;
  padding: 0;
}

.table.section table {
  margin-top: 2rem;
  width: 230px;
  border-collapse: collapse;
  line-height: 20px;
}

.table.section table td:first-child {
  width: 115px;
}

.table.section table .highlight {
  color: #000;
  font-weight: bold;
}
