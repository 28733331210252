.menu {
  background-color: rgb(245, 233, 207);
  font-size: 18px;
  display: flex;
  padding: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.menu > a {
  margin-left: 1.5rem;
}

.is-active {
  font-weight: bold;
}