.separator {
  position: relative;
  height: 40px;
  padding-top: 40px;
}

.separator img {
  width: 40px;
  position: absolute;
  top: 16px;
  left: calc(50% - 20px);
  z-index: 1;
}

.separator hr {
  margin: 0;
  position: absolute;
}

.separator hr.left {
  width: calc(50% - 20px);
}

.separator hr.right {
  left: calc(50% + 15px);
  width: calc(50% - 15px);
}